<template>
  <div class="create-wrap">
    <div class="bread-wrap">
      <i class="iconfont icon-shouji"></i
      ><span class="bread-title">云手机/创建通用云手机</span>
    </div>
    <div class="content">
      <div class="content-right">
        <div class="ffxx">
          <p class="title-wrap">
            <i class="iconfont icon-jiaofeizhangdan titleicon"></i
            ><span class="title">付费信息</span>
          </p>
          <div class="ff-detail">
            <span class="dtcontent">云手机类型</span
            ><span class="dttext">{{ detailform.guige }}</span>
          </div>
          <div class="ff-detail">
            <span class="dtcontent">选择区域</span
            ><span class="dttext">{{ detailform.region }}</span>
          </div>
          <div class="ff-detail">
            <span class="dtcontent">购买数量</span
            ><span class="dttext">{{ "x" + detailform.num }}</span>
          </div>
          <div class="ff-detail">
            <span class="dtcontent">购买时长</span
            ><span class="dttext">{{ detailform.sc }}</span>
          </div>
          <div class="ff-detail">
            <span class="dtcontent">立减</span
            ><span class="dtdiscount">{{ detailform.discount }} 元</span>
          </div>
          <div class="hori-line"></div>
          <div class="ff-detail">
            <span class="dtprice">合计费用</span
            ><span class="dtamount">{{ detailform.total }} 元</span>
          </div>
          <el-radio-group class="payradio" v-model="detailform.PayType">
            <el-radio
              label="amount"
              :disabled="balanceNotAvailable"
              class="itemradio"
              >余额支付(可用:{{ balance }})</el-radio
            >
            <el-radio label="wechatpay_native" class="itemradio"
              ><i class="iconfont icon-weixinzhifu"></i>微信支付</el-radio
            >
            <el-radio label="alipay_page" class="itemradio">
              <div class="itemradio-content">
                <img
                  src="@/assets/icon-alipay.png"
                  class="icon-alipay"
                />支付宝支付
              </div>
            </el-radio>
          </el-radio-group>
          <el-button
            v-if="!isSubmit"
            @click="navorderconfirm"
            class="paybtn"
            size="small"
            :disabled="isSubmit"
            type="primary"
            >提交订单</el-button
          >
          <el-button
            v-else
            @click="navorderconfirm"
            class="paybtn2"
            size="small"
            :disabled="isSubmit"
            type="primary"
            >提交订单</el-button
          >
        </div>
      </div>
      <div class="content-left">
        <!-- 地域 -->
        <div class="diyu">
          <p class="title-wrap">
            <i class="iconfont icon-diyufenbu titleicon"></i
            ><span class="title">目标地域</span>
          </p>
          <div class="tabs">
            <el-tabs
              v-model="activeName"
              type="border-card"
              @tab-click="handleClick"
            >
              <el-tab-pane name="china">
                <span slot="label" style="font-size: 16px">国内</span>
                <div class="selectdiyu">
                  <div class="select-1">
                    <p
                      v-for="(item, index) in ChinaRegions"
                      :key="index"
                      @click="selectdy(item.RegionId, item)"
                      :class="[
                        {
                          'select-content-active':
                            regionselect == item.RegionId,
                        },
                        'select-content',
                      ]"
                    >
                      {{ item.Name2 }}
                    </p>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="southeastasia">
                <span slot="label" style="font-size: 16px">亚太</span>
                <div class="selectdiyu">
                  <div class="select-1">
                    <p
                      v-for="(item, index) in SoutheastAsiaRegions"
                      :key="index"
                      @click="selectdy(item.RegionId, item)"
                      :class="[
                        {
                          'select-content-active':
                            regionselect == item.RegionId,
                        },
                        'select-content',
                      ]"
                    >
                      {{ item.Name2 }}
                    </p>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="america">
                <span slot="label" style="font-size: 16px">美洲</span>
                <div class="selectdiyu">
                  <div class="select-1">
                    <p
                      v-for="(item, index) in AmericaRegions"
                      :key="index"
                      @click="selectdy(item.RegionId, item)"
                      :class="[
                        {
                          'select-content-active':
                            regionselect == item.RegionId,
                        },
                        'select-content',
                      ]"
                    >
                      {{ item.Name2 }}
                    </p>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="europe">
                <span slot="label" style="font-size: 16px">欧洲</span>
                <div class="selectdiyu">
                  <div class="select-1">
                    <p
                      v-for="(item, index) in EuropeRegions"
                      :key="index"
                      @click="selectdy(item.RegionId, item)"
                      :class="[
                        {
                          'select-content-active':
                            regionselect == item.RegionId,
                        },
                        'select-content',
                      ]"
                    >
                      {{ item.Name2 }}
                    </p>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="middleeast">
                <span slot="label" style="font-size: 16px">中东</span>
                <div class="selectdiyu">
                  <div class="select-1">
                    <p
                      v-for="(item, index) in MiddleEastRegions"
                      :key="index"
                      @click="selectdy(item.RegionId, item)"
                      :class="[
                        {
                          'select-content-active':
                            regionselect == item.RegionId,
                        },
                        'select-content',
                      ]"
                    >
                      {{ item.Name2 }}
                    </p>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="africa">
                <span slot="label" style="font-size: 16px">非洲</span>
                <div class="selectdiyu">
                  <div class="select-1">
                    <p
                      v-for="(item, index) in AfricaRegions"
                      :key="index"
                      @click="selectdy(item.RegionId, item)"
                      :class="[
                        {
                          'select-content-active':
                            regionselect == item.RegionId,
                        },
                        'select-content',
                      ]"
                    >
                      {{ item.Name2 }}
                    </p>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <!-- 规格 -->
        <div class="guige">
          <p class="title-wrap">
            <i class="iconfont icon-fenzutongji titleicon"></i
            ><span class="title">云手机规格</span>
          </p>
          <div class="select-guige">
            <div
              @click="selectguige(item.Id, item)"
              v-for="(item, index) in Products"
              :key="index"
              :class="['selectcon', { 'selectcon-active': guige == item.Id }]"
            >
              <p
                class="ggtp2"
                v-if="
                  (item.Id == 'universal' && !haveBoutique) ||
                  (item.Id == 'phone-shared' && !haveShare) ||
                  (item.Id == 'phone-exclusive' && !haveExclusive) ||
                  (item.Id == 'phone-noip' && !haveNoip) ||
                  (item.Id == 'phone-social' && !haveSocial)
                "
              >
                {{ `${item.Name}` }}
                <el-tooltip
                  v-if="item.Id == 'phone-noip'"
                  class="item"
                  effect="dark"
                  placement="bottom-start"
                >
                  <div slot="content">
                    <p>点击查看教程</p>
                    <p>如何配置IP?</p>
                    <p><span>方法1:</span>云手机列表页面->更多->配置IP;</p>
                    <p>
                      <span>方法2:</span>云手机操作页面->批量操作->批量配置IP;
                    </p>
                  </div>
                  <i
                    @click="noipteach"
                    class="el-icon-question installicon"
                  ></i>
                </el-tooltip>
              </p>
              <p :class="['ggtp3', { ggtp: guige == item.Id }]" v-else>
                {{ `${item.Name}` }}
                <el-tooltip
                  v-if="item.Id == 'phone-noip'"
                  class="item"
                  effect="dark"
                  placement="bottom-start"
                >
                  <div slot="content">
                    <p>点击查看教程</p>
                    <p>如何配置IP?</p>
                    <p><span>方法1:</span>云手机列表页面->更多->配置IP;</p>
                    <p>
                      <span>方法2:</span>云手机操作页面->批量操作->批量配置IP;
                    </p>
                  </div>
                  <i
                    @click="noipteach"
                    class="el-icon-question installicon"
                  ></i>
                </el-tooltip>
              </p>

              <div
                class="hori-line2"
                v-if="
                  (item.Id == 'universal' && !haveBoutique) ||
                  (item.Id == 'phone-shared' && !haveShare) ||
                  (item.Id == 'phone-exclusive' && !haveExclusive) ||
                  (item.Id == 'phone-noip' && !haveNoip) ||
                  (item.Id == 'phone-social' && !haveSocial)
                "
              ></div>
              <div
                :class="['hori-line', { 'hori-line3': guige == item.Id }]"
                v-else
              ></div>

              <div
                v-if="
                  (item.Id == 'universal' && !haveBoutique) ||
                  (item.Id == 'phone-shared' && !haveShare) ||
                  (item.Id == 'phone-exclusive' && !haveExclusive) ||
                  (item.Id == 'phone-noip' && !haveNoip) ||
                  (item.Id == 'phone-social' && !haveSocial)
                "
              >
                <p v-for="desc in productDesc[index]" class="ggdes2">
                  {{ `${desc}` }}
                </p>
                <p class="ggdessource" style="text-align: left">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源补充中...
                </p>
              </div>
              <div v-else>
                <p
                  v-for="desc in productDesc[index]"
                  :class="['ggdes3', { ggdes: guige == item.Id }]"
                  v-html="hightlight(desc)"
                ></p>
              </div>

              <!-- <p v-for="desc in productDesc[index]" class="ggdes">
                {{ `${desc}` }} -->
              <!-- </p> -->
            </div>
          </div>
        </div>
        <!-- 配置管理 -->
        <div class="pzgl">
          <el-collapse
            v-model="activecollapse"
            @change="collapsechange"
            accordion
          >
            <el-collapse-item name="1">
              <template slot="title">
                <p class="title-wrap">
                  <i class="iconfont icon-caozuo-qunkong titleicon"></i
                  ><span class="title">配置管理</span>
                  <span class="collapseicon">{{
                    activecollapse == 1 ? "收起" : "展开"
                  }}</span>

                  <span style="margin-left: 10px; color: red">*</span>
                  <span style="margin-left: 5px; font-size: 10px"
                    >一般采用默认值即可，特殊需求时配置</span
                  >
                </p>
              </template>
              <div class="collapse-content">
                <el-row>
                  <el-col :xs="3" :sm="3" :md="3" :lg="3" :xl="2"
                    ><p class="label">分辨率</p></el-col
                  >
                  <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="4">
                    <el-select
                      v-model="resolutionvalue"
                      placeholder="请选择分辨率"
                      @change="selectchange"
                      size="small"
                    >
                      <el-option
                        v-for="item in resolutoptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="3" :sm="3" :md="3" :lg="3" :xl="2"
                    ><p class="label">DPI</p></el-col
                  >
                  <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="8">
                    <el-slider
                      :disabled="true"
                      v-model="dpinum"
                      :min="320"
                      :max="480"
                      :step="40"
                      show-input
                      @change="sliderchange"
                    >
                    </el-slider>
                  </el-col>
                </el-row>
                <el-row>
                  <el-checkbox
                    @change="installchange"
                    v-model="isinstallapp"
                    label="预装海外APP库"
                    size="medium"
                    class="installapp"
                  ></el-checkbox>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="安装海外APP库可以让环境更真实"
                    placement="bottom-start"
                  >
                    <i class="el-icon-question installicon"></i>
                  </el-tooltip>
                </el-row>
                <el-row>
                  <el-checkbox
                    @change="isrootchange"
                    v-model="isroot"
                    label="开启ROOT"
                    size="medium"
                    class="installapp"
                  ></el-checkbox>
                </el-row>
                <el-row>
                  <el-col :span="10"> </el-col>
                </el-row>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <!-- 时长选择 -->
        <div class="scxz">
          <p class="title-wrap">
            <i class="iconfont icon-zhongbiao titleicon"></i>
            <span class="title">时长选择</span>
          </p>
          <div class="select-sc">
            <div class="select-item">
              <div
                v-for="(item, index) in buyTime"
                :key="index"
                @click="selectsc(item.chargeId)"
                :class="['day', { 'sc-active': sc == item.chargeId }]"
              >
                <span>{{ item.text }}</span>
                <span class="sc-discount" v-if="item.discount"
                  >({{ item.discount }})</span
                >
              </div>
            </div>
          </div>
          <div class="auto-renew">
            <span class="auto-renew-title">自动续费</span>
            <el-switch
              v-model="payform.AutoRenewSwitch"
              active-color="rgba(60, 100, 235, 1)"
            ></el-switch>
            <span class="auto-renew-title title2" v-if="payform.AutoRenewSwitch"
              >自动续费时长</span
            >
            <el-select
              v-if="payform.AutoRenewSwitch"
              class="auto-renew-select"
              size="small"
              v-model="payform.AutoRenew"
              change="autoRenewChange"
            >
              <el-option
                v-for="item in autoRenewOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <!-- 购买数量 -->
        <div class="gmsl">
          <p class="title-wrap">
            <i class="iconfont icon-gouwucheman titleicon"></i>
            <span class="title">购买数量</span>
          </p>
          <div class="select-number">
            <el-input-number
              :min="min"
              :max="10000"
              :step="realStep"
              v-model.number="payform.BuyCount"
              size="small"
              @change="selectnum"
            ></el-input-number>
          </div>
        </div>
        <!-- 设置分组 -->
        <div class="fzxz">
          <p class="title-wrap">
            <i class="iconfont icon-yewuzu titleicon"></i
            ><span class="title">设置分组</span>
          </p>
          <div class="select-fz">
            <span class="fztext">名称</span>
            <el-input
              style="width: 180px"
              size="small"
              v-model="payform.Name"
              placeholder="请填写云手机名称"
            >
            </el-input>
          </div>
          <div class="select-fz">
            <span class="fztext">分组</span>
            <el-select
              size="small"
              v-model="payform.GroupId"
              filterable
              placeholder="请选择分组"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getToken } from "@/utils/auth";
import navigation from "@/utils/navigation";
import { specifications, phoneType } from "@/utils/optionList";
import { mapGetters } from "vuex";
export default {
  name: "createphone",
  data() {
    return {
      activeName: "china",
      isinstallapp: true,
      regionselect: 0,
      regionitem: {
        Prices: [],
      },
      guige: "0",
      guigeitem: {
        Prices: [],
      },
      buyTime: [
        {
          chargeId: 30,
          text: "月付",
        },
        {
          chargeId: 90,
          text: "季付",
          discount: "9折",
        },
        {
          chargeId: 180,
          text: "半年付",
          discount: "85折",
        },
        {
          chargeId: 360,
          text: "年付",
          discount: "8折",
        },
      ],
      sc: 30,
      specifications,
      IpRegions: [],
      payradio: true,
      Regions: [], //云手机地域
      ChinaRegions: [],
      SoutheastAsiaRegions: [],
      AmericaRegions: [],
      EuropeRegions: [],
      MiddleEastRegions: [],
      AfricaRegions: [],
      Products: [],
      productDesc: [],
      options: [],
      realStep: 1,
      min: 1,
      detailform: {
        region: "",
        guige: "",
        num: 1,
        sc: "1天",
        total: "--",
        discount: 0,
        PayType: "amount",
      },
      payform: {
        ProductId: "",
        BuyCount: 1,
        ChargeId: 1,
        IpRegionId: "",
        Name: "",
        GroupId: "",
        AutoRenew: 30,
        AutoRenewSwitch: true,
        Ext: {
          DisablePreinstallApps: false,
          Root: false,
          Resolution: "1080x1920",
          Dpi: 480,
        },
      },
      balanceNotAvailable: false,
      haveBoutique: false,
      haveShare: false,
      haveExclusive: false,
      haveNoip: false,
      haveSocial: false,
      isSubmit: false,
      isaccount: true,
      balance: 0.01,
      autoRenewOptions: [
        // { value: 0, label: '不开启' },
        { value: 30, label: "按月" },
        { value: 90, label: "按季度" },
        { value: 180, label: "按半年" },
        { value: 360, label: "按年" },
      ],
      activecollapse: "",
      resolutoptions: [
        {
          value: "1",
          label: "720x1280",
        },
        {
          value: "2",
          label: "1080x1920",
        },
      ],
      resolutionvalue: "2",
      dpinum: 480,
      isroot: false,
    };
  },
  computed: {
    ...mapGetters(["subAccount"]),
  },
  watch: {
    "payform.IpRegionId": {
      handler: function (val) {
        this.$store
          .dispatch("group/phoneregionstocklist", {
            RegionId: this.payform.IpRegionId,
          })
          .then((res) => {
            if (Object.keys(res).length !== 0) {
              this.haveBoutique = res.Universal;
              this.haveShare = res.PhoneXXX;
              this.haveExclusive = res.PhoneExclusive;
              this.haveNoip = res.PhoneNoip;
              this.haveSocial = res.PhoneSocial;
              if (
                (!this.haveBoutique && this.guige == "universal") ||
                (!this.haveShare && this.guige == "phone-shared") ||
                (!this.haveExclusive && this.guige == "phone-exclusive") ||
                (!this.haveNoip && this.guige == "phone-noip") ||
                (!this.haveSocial && this.guige == "phone-social")
              ) {
                this.isSubmit = true;
              } else {
                this.isSubmit = false;
              }
            }
          });
      },
    },
    payform: {
      handler: function (val) {
        if (this.payform.ProductId == "" || this.payform.IpRegionId == "") {
          return;
        }
        this.$store
          .dispatch("pay/getphoneprice", { ...this.payform })
          .then((res) => {
            //console.log("phone get price: " + JSON.stringify(res));
            if (res.RetCode == 0) {
              this.detailform.discount = res.Discounts / 100;
              this.detailform.total = res.TotalAmount / 100; //分转换为元
              if (this.detailform.total > this.balance) {
                this.isaccount = false;
                this.balanceNotAvailable = true;
                this.detailform.PayType = "wechatpay_native";
              } else {
                this.isaccount = true;
                this.balanceNotAvailable = false;
                this.detailform.PayType = "amount";
              }
            } else {
              console.log("failed to get phone price: " + res.Message);
            }
          });
      },
      deep: true,
    },
  },
  methods: {
    noipteach() {
      window.open(
        "https://pathlive.cn-bj.ufileos.com/%E6%97%A0%E7%BD%91%E7%89%88%E4%BA%91%E6%89%8B%E6%9C%BA%E5%BF%AB%E9%80%9F%E9%85%8D%E7%BD%AE%E6%89%8B%E5%86%8C.pdf"
      );
    },
    hightlight(str) {
      var search = "";
      var searchArray = ["购买1-2部时与他人共享IP"];

      for (let i = 0; i < searchArray.length; i++) {
        if (str.includes(searchArray[i])) {
          search = searchArray[i];
        }
      }
      var subStr = new RegExp(search); // 匹配关键字正则
      let replaceString =
        '<span style="color:rgb(30, 30, 30);font-weight:500">' +
        search +
        "</span>"; // 高亮替换v-html值
      let result = str.replace(subStr, replaceString);
      return result;
    },
    sliderchange(value) {
      this.dpinum = value;
      this.payform.Ext.Dpi = value;
      console.log(this.payform.Ext.Dpi);
    },
    selectchange(value) {
      if (value == 1) {
        this.payform.Ext.Resolution = "720x1280";
        this.payform.Ext.Dpi = 320;
        this.dpinum = 320;
      }
      if (value == 2) {
        this.payform.Ext.Resolution = "1080x1920";
        this.payform.Ext.Dpi = 480;
        this.dpinum = 480;
      }
    },
    collapsechange(value) {
      this.activecollapse = value;
    },
    isrootchange(value) {
      this.isroot = value;
      this.payform.Ext.Root = value;
    },
    installchange(value) {
      this.isinstallapp = value;
      if (value) {
        this.payform.Ext.DisablePreinstallApps = false;
      } else {
        this.payform.Ext.DisablePreinstallApps = true;
      }
      console.log(this.payform.Ext.DisablePreinstallApps);
    },
    selectdy(index, item) {
      this.regionselect = index;
      this.regionitem = item;
      this.payform.IpRegionId = item.RegionId;
      this.detailform.region = item.Name2;
    },
    selectguige(index, item) {
      // if (item.Id == "live") {
      //   this.min = 1
      //   this.realStep = 1;
      //   this.payform.BuyCount = 1;
      //   this.detailform.num = 1;
      // }
      if (item.Id == "phone-exclusive") {
        this.min = 3;
        this.realStep = 3;
        this.payform.BuyCount = 3;
        this.detailform.num = 3;
      } else {
        this.min = 1;
        this.realStep = 1;
        this.payform.BuyCount = 1;
        this.detailform.num = 1;
      }
      this.guige = index;
      this.guigeitem = item;
      this.payform.ProductId = item.Id;
      this.detailform.guige = item.Name;
      if (
        (index == "universal" && !this.haveBoutique) ||
        (index == "phone-shared" && !this.haveShare) ||
        (index == "phone-exclusive" && !this.haveExclusive) ||
        (index == "phone-noip" && !this.haveNoip) ||
        (index == "phone-social" && !this.haveSocial)
      ) {
        this.isSubmit = true;
      } else {
        this.isSubmit = false;
      }
    },
    selectsc(val) {
      this.sc = val;
      this.payform.ChargeId = val;
      this.autoRenewChange();
      switch (val) {
        case 30:
          this.detailform.sc = "1月";
          break;
        case 90:
          this.detailform.sc = "1季度";
          break;
        case 180:
          this.detailform.sc = "半年";
          break;
        case 360:
          this.detailform.sc = "1年";
          break;
      }
    },
    selectnum(val) {
      console.log(val);
      if (!val) {
        this.payform.BuyCount = this.min;
        this.detailform.num = this.min;
        return;
      }
      this.payform.BuyCount = val;
      this.detailform.num = val;
    },
    navorderconfirm() {
      console.log(this.payform);
      if (this.subAccount) {
        this.$message.warning(
          "子账号暂不支持购买云手机功能，请登录主账号进行购买~"
        );
        return;
      }
      if (!this.$store.state.user.authenticated) {
        this.$message.warning(
          "未实名认证，无法进行此操作，可以点击头像中的个人中心进行认证."
        );
        return;
      }
      if (this.detailform.PayType == "amount" && this.balanceNotAvailable) {
        this.$message.warning("余额不足，请充值或更换其他支付方式");
        return;
      }

      const h = this.$createElement;
      this.$confirm(
        h("p", null, [
          h("span", null, "请确认您选择的目标地域- "),
          h(
            "span",
            { style: "color: red;font-weight:bold;font-size:22px" },
            this.detailform.region
          ),
          h("span", null, " , 是否继续?"),
        ]),
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          if (!this.payform.AutoRenewSwitch) {
            this.payform.AutoRenew = 0;
          }
          navigation.navigateTo({
            url: "orderconfirm",
            params: {
              payload: {
                ...this.payform,
              },
              detail: {
                ...this.detailform,
              },
            },
          });
        })
        .catch(() => {});
    },
    getUserBalance() {
      this.$store.dispatch("user/getuserwallet").then((res) => {
        console.log("user wallet: " + JSON.stringify(res));
        if (res.RetCode == 0) {
          this.balance = res.Balance / 100;
        }
      });
    },
    autoRenewChange() {
      if (
        this.payform.ChargeId == 7 ||
        this.payform.ChargeId == 10001 ||
        this.payform.ChargeId == 10002
      ) {
        this.payform.AutoRenew = 30;
      } else {
        this.payform.AutoRenew = this.payform.ChargeId;
      }
    },
    handleClick(tab, event) {
      if (tab.index == 1) {
        this.selectdy(
          this.SoutheastAsiaRegions[0].RegionId,
          this.SoutheastAsiaRegions[0]
        );
      } else if (tab.index == 2) {
        this.selectdy(this.AmericaRegions[0].RegionId, this.AmericaRegions[0]);
      } else if (tab.index == 3) {
        this.selectdy(this.EuropeRegions[0].RegionId, this.EuropeRegions[0]);
      } else if (tab.index == 4) {
        this.selectdy(
          this.MiddleEastRegions[0].RegionId,
          this.MiddleEastRegions[0]
        );
      } else if (tab.index == 5) {
        this.selectdy(this.AfricaRegions[0].RegionId, this.AfricaRegions[0]);
      } else {
        this.selectdy(this.ChinaRegions[0].RegionId, this.ChinaRegions[0]);
      }
    },
    getuserinfo() {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        if (res.RetCode == 0) {
        }
      });
    },
  },
  activated() {
    this.getUserBalance();
    this.getuserinfo();
  },
  mounted() {
    var hasToken = getToken();
    this.$store
      .dispatch("pay/getipregionlist", { Authorization: hasToken })
      .then((res) => {
        console.log("get region list response: " + JSON.stringify(res));
        if (res.RetCode == 0) {
          this.Regions = res.IpRegions;
          res.IpRegions.length &&
            res.IpRegions.forEach((item, index) => {
              if (item.Classification == "欧洲") {
                this.EuropeRegions.push(item);
              } else if (item.Classification == "美洲") {
                this.AmericaRegions.push(item);
              } else if (item.Classification == "亚太") {
                this.SoutheastAsiaRegions.push(item);
              } else if (item.Classification == "中东") {
                this.MiddleEastRegions.push(item);
              } else if (item.Classification == "非洲") {
                this.AfricaRegions.push(item);
              } else {
                this.ChinaRegions.push(item);
              }
            });
          this.selectdy(this.ChinaRegions[0].RegionId, this.ChinaRegions[0]);
        }
      });

    this.$store
      .dispatch("pay/getproductlist", { Authorization: hasToken })
      .then((res) => {
        console.log("get product list response: " + JSON.stringify(res));
        console.log(phoneType);
        if (res.RetCode == 0) {
          let list = res.Products.map((item, index) => {
            phoneType.forEach((pitem) => {
              if (pitem.Id == item.ProductId) {
                item = pitem;
                this.productDesc[index] = item.Description.split("&");
              }
            });
            return item;
          });
          this.Products = list;
          console.log(this.Products);
          this.selectguige(this.Products[0].Id, this.Products[0]);
        }
      });

    // this.Products = phoneType.map((item, index) => {
    //   this.productDesc[index] = item.Description.split("&");
    //   return item;
    // });
    //console.log("phone guge: " + JSON.stringify(this.Products));
    // this.selectguige(this.Products[0].Id, this.Products[0]);

    this.selectsc(this.sc);

    this.$store.dispatch("group/getgrouplist").then((res) => {
      if (res.RetCode == 0) {
        //console.log("get group list response: " + JSON.stringify(res));
        res.Groups.length &&
          res.Groups.forEach((item, index) => {
            let data = {
              label: item.Name,
              value: item.Id,
            };
            this.options.push(data);
          });
        this.payform.GroupId = this.options.length ? this.options[0].value : "";
      }
    });

    this.$store
      .dispatch("pay/getextrachargetype", { ResourceType: "PHONE" })
      .then((res) => {
        if (res.RetCode == 0) {
          console.log(res);
          if (res.ChargeTypes && res.ChargeTypes.length) {
            res.ChargeTypes.map((c) => {
              this.buyTime.push({
                chargeId: c.ChargeId,
                text: c.ChargeName,
                discount: c.DiscountText,
              });
            });
          }
        }
      });

    //获取用户余额
    this.getUserBalance();
    this.getuserinfo();
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>